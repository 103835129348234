import React, { useEffect, useState } from "react";
import "./about.css";
import "./about.responsive.css";
import PagesIndex from "../../../../PagesIndex";
import Index from "../../../../../container/Index";

export default function About() {
  
  const [animationCoinProps, setAnimationCoinProps] = useState({
    x: 200,
    opacity: 0,
    rotate: 360,
  });

  const [whileInViewCoinProps, setWhileInViewCoinProps] = useState({
    x: 0,
    opacity: 1,
    rotate: 19,
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1600) {
      
      } else if (screenWidth >= 1366) {
        setWhileInViewCoinProps({
          x: 20,
          opacity: 1,
          rotate: 19,
        });
      } else if (screenWidth >= 1280) {
        setWhileInViewCoinProps({
          x: 20,
          opacity: 1,
          rotate: 19,
        });
      } else if (screenWidth >= 992) {
   
      } else {
       
      }
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Index.Box className="about-section">
      <Index.Box className="container">
        <Index.Box className="page-title-main about-section-title">
          <Index.motion.div
            animate={{ scale: 0.9 }}
            transition={{ duration: 0.8 }}
            whileInView={{ scale: 1 }}
          >
            <Index.Typography className="page-title">
              About Wif Coin
            </Index.Typography>
          </Index.motion.div>
        </Index.Box>
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            className="about-row"
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 6",
                lg: "span 7",
              }}
              className="grid-column"
            >
              <Index.Box className="about-containt-main">
                <Index.Box className="about-img-main">
                  <img
                    src={PagesIndex.Svg.aboutimg}
                    alt="about img"
                    className="about-img"
                  />
                  <img
                    src={PagesIndex.Png.aboutdog} 
                    alt="logo"
                    className="about-dog-img "
                  />
                  <Index.Box className="about-dog-rotated">
                    <Index.motion.div
                      // animate={{ x: 200, opacity: 0, rotate: 360 }}
                      // transition={{ duration: 0.8 }}
                      // whileInView={{ x: 0, opacity: 1, rotate: 19 }}
                      animate={animationCoinProps}
                      transition={{ duration: 0.8 }}
                      whileInView={whileInViewCoinProps}
                    
                    >
                      <img
                        src={PagesIndex.Svg.logo}
                        alt="logo"
                        className="about-coin"
                      />
                    </Index.motion.div>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="cloud-details">
                  <Index.Box className="cloud-about-one">
                    <Index.motion.div
                    >
                      <img src={PagesIndex.Svg.acloudone} alt="cloud"></img>
                    </Index.motion.div>
                  </Index.Box>
                  <Index.Box className="cloud-about-two">
                    {" "}
                    <Index.motion.div
                    >
                      <img src={PagesIndex.Svg.acloudtwo} alt="cloud"></img>
                    </Index.motion.div>
                  </Index.Box>
                  <Index.Box className="cloud-about-three">
                    <Index.motion.div
                    >
                      <img src={PagesIndex.Svg.acloudthree} alt="cloud"></img>
                    </Index.motion.div>
                  </Index.Box>
                  <Index.Box className="cloud-about-four">
                    <Index.motion.div
                    >
                      <img src={PagesIndex.Svg.acloudfour} alt="cloud"></img>
                    </Index.motion.div>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 6",
                lg: "span 5",
              }}
              className="grid-column"
            >
              <Index.motion.div
                animate={{ x: 200, opacity: 0 }}
                transition={{ duration: 0.8 }}
                whileInView={{ x: 0, opacity: 1 }}
              >
                <Index.Box className="about-containt-main">
                  <Index.Box className="about-para-main">
                    <Index.Typography className="about-para">
                      Lorem ipsum dolor sit amet consectetur. A fringilla quis
                      integer ut. Hendrerit fringilla ornare dolor ornare amet
                      ac elementum in nunc. Non elit. Lorem ipsum dolor sit amet
                      consectetur. A fringilla quis integer ut. Hendrerit
                      fringilla ornare dolor ornare amet ac elementum in nunc.
                      Non elit.
                    </Index.Typography>
                    <Index.Typography className="about-para">
                      Lorem ipsum dolor sit amet consectetur. A fringilla quis
                      integer ut. Hendrerit fringilla ornare dolor ornare amet
                      ac elementum in nunc.{" "}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.motion.div>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
