import dogvideo from './images/video/dog.gif';








const Video = {
    dogvideo
}

export default Video;