import React from 'react'

function foxlight() {
  return (
<svg  class="animated-svg" width="365" height="545" viewBox="0 0 365 545" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="animated-path" opacity="0.39" d="M364.309 537.635L232.587 0.75L0 134.905L352.994 544.338L364.309 540.535" fill="url(#paint0_linear_457_609)"/>
<defs>
<linearGradient id="paint0_linear_457_609" x1="368.969" y1="539.314" x2="122.415" y2="79.2493" gradientUnits="userSpaceOnUse">
<stop stop-color="#03FFEF"/>
<stop offset="1" stop-color="#70CBD0" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

  )
}

export default foxlight