import React, { useState } from "react";
import PagesIndex from "../../../../PagesIndex";
import Index from "../../../../../container/Index";
import "./tokenomics.css";
import "./tokenomics.responsive.css";

export default function Tokenomics() {
  const [copied, setCopied] = useState(false);

  const handleCopyToken = () => {
    const tokenAddress = "HhJpBhRRng56VsyLuTDL5BvHkXqsrahT"; // Your token address
    window.navigator.clipboard
      .writeText(tokenAddress)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 5000); // Hide tooltip after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy token address:", error);
        // Optionally, you can show an error message or perform any other action
      });
  };

  return (
    <Index.Box className="tokenomics-section">
      <Index.Box className="container">
        <Index.Box className="page-title-main tokenomics-section-title">
          <Index.motion.div
            animate={{ scale: 0.9 }}
            transition={{ duration: 0.8 }}
            whileInView={{ scale: 1 }}
          >
            <Index.Box className="page-title">Tokenomics</Index.Box>
          </Index.motion.div>
        </Index.Box>
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            className="tokenomics-row"
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="tokenomics-containt-main">
                <Index.Box className="tokenomics-flex-box-main">
                  <Index.Box className="tokenomics-box">
                    <Index.Typography className="tokenomics-super-senior-text">
                      Symbol
                    </Index.Typography>
                    <Index.Typography className="tokenomics-sulphurpoint-text">
                      $wif
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="tokenomics-box">
                    <Index.Typography className="tokenomics-box-text">
                      No Taxes,
                    </Index.Typography>
                    <Index.Typography className="tokenomics-box-text">
                      It's that simple.
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="tokenomics-box">
                    <Index.Typography className="tokenomics-box-text">
                      LP tokens are
                    </Index.Typography>
                    <Index.Typography className="tokenomics-box-text">
                      burnt
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="token-address-contait-main">
                  <Index.motion.div
                    animate={{ scale: 0.9 }}
                    transition={{ duration: 0.8 }}
                    whileInView={{ scale: 1 }}
                  >
                    <Index.Typography className="tokenomics-token-text">
                      Token Address
                    </Index.Typography>
                  </Index.motion.div>
                  <Index.Box className="token-wallet-input-main">
                    <Index.Box className="token-copy-flex">
                      <Index.Box className="token-copy-box">
                        HhJpBhRRng56VsyLuTDL5BvHkXqsrahT
                      </Index.Box>
                      <Index.Box className="token-copy-rela"> 
                        <Index.Button
                          className="token-copy-btn"
                          onClick={handleCopyToken}
                        >
                          <img
                            src={PagesIndex.Svg.copyIcon}
                            alt="copy"
                            className="copy-icon"
                          />
                        </Index.Button>
                        {copied && (
                          <Index.Typography className="copied-tooltip">
                            Copied!
                          </Index.Typography>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="token-bone-animation">
                    <PagesIndex.Svg.bone className={copied ? "star1" : ""} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="tokenomics-containt-main house-tokenomics">
                <Index.Box className="tokenomics-img-main">
                  <img
                    src={PagesIndex.Png.tokenomicsImg}
                    alt="tokenomics img"
                    className="tokenomics-img"
                  />
                  <Index.Box className="smoke-box">
                    <div id="chimney-smoke-box">
                      <div id="chimney-smoke1" class="chimney-smoke"></div>
                      <div id="chimney-smoke2" class="chimney-smoke"></div>
                      <div id="chimney-smoke3" class="chimney-smoke"></div>
                      <div id="chimney-smoke4" class="chimney-smoke"></div>
                      <div id="chimney-smoke5" class="chimney-smoke"></div>
                      <div id="chimney-smoke6" class="chimney-smoke"></div>
                    </div>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
