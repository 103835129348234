import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";

function ScrollTopButton() {
  const [showButton, setShowButton] = useState(false);

  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        return setShowButton(true);
      }else{
        return setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <Index.Button className="abc" onClick={handleScrollToTop}>
          <img src={PagesIndex.Svg.arrowup} className="arrow-up" />
          {/* <FaArrowCircleUp onClick={scrollToTop}  
    style={{display: visible ? 'inline' : 'none'}} />  */}
        </Index.Button>
      )}
    </>
  );
}

export default ScrollTopButton;
