import React, { useEffect, useRef, useState } from 'react'
import { useOutletContext } from "react-router-dom";
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'


export default function Home() {
  const sectionsRefs = {
    ['section-1']: useRef(null),
    ['section-2']: useRef(null),
    ['section-3']: useRef(null),
    ['section-4']: useRef(null),
  };

  const [currentTab,setCurrentTab]=useOutletContext()

  const handleScroll = () => {
    // setCurrentTab('')
    for (const key in sectionsRefs) {
      const sectionRef = sectionsRefs[key];
      if (sectionRef.current) {
        const targetTop = sectionRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        const threshold = windowHeight * 0.8;
        if (targetTop < threshold) {
          if(window.screen.width > 991)
            {
              setCurrentTab(key);
            }
              
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
       <Index.Box className="home-hero-main">
        <PagesIndex.HeroSection/>
      </Index.Box>
      <Index.Box ref={sectionsRefs['section-1']} className="home-about-main" id="section-1">
        <PagesIndex.About />
      </Index.Box>
      <Index.Box ref={sectionsRefs['section-2']} className="home-tokenomics-main" id="section-2">
        <PagesIndex.Tokenomics />
      </Index.Box>
      <Index.Box ref={sectionsRefs['section-3']} className="home-roadmap-main" id="section-3">
        <PagesIndex.RoadMap />
      </Index.Box>
      <Index.Box ref={sectionsRefs['section-4']} className="home-follow-main" id="section-4">
        <PagesIndex.Follow />
      </Index.Box>
    </>
  )
}
