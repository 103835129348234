import React, { useState, useEffect } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import "./defaulLayout.css";

const headerMenu = [
  {
    name: "About",
    id: "section-1",
  },
  {
    name: "Tokenomics",
    id: "section-2",
  },
  {
    name: "Roadmap",
    id: "section-3",
  },
  {
    name: "Social",
    id: "section-4",
  },
  {
    name: "Wif paper",
  },
  {
    name: "Buy DWS",
  },
];

export default function Header({ currentTab,setCurrentTab }) {

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    if (!menuOpen) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  };

  const handleOverlayClick = () => {
    setMenuOpen(false);
    document.body.classList.remove("body-overflow");
  };


  const [isActive, setIsActive] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    setIsActive((current) => !current);
    document.body.classList.toggle("body-overflow", !isActive);
  };

  const handleScrollToDiv = (id) => {
    setMenuOpen(false);
    setCurrentTab(id)
    document.body.classList.remove("body-overflow");
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>

      <header className={`header-main page-header ${isSticky ? "sticky" : ""}`}>
        <Index.Box
          className={`overlay-mobile-details ${menuOpen ? "overlay-open" : ""}`}
          onClick={handleOverlayClick}
        ></Index.Box>
        <Index.Box className="container">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              className="header-row"
            >
              <Index.Box
                className="grid-column"
              >
                <Index.Box className="header-col">
                  <Index.Box className="header-logo-main">
                    <img
                      src={PagesIndex.Png.coinlogo}
                      className="logo"
                      alt="logo"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box

                className={`grid-column responsive-none-grid ${menuOpen ? "grid-none-open" : ""}`}
              >
                <Index.Box
                  // className="header-col header-pos-fixed"
                  className={`header-col header-pos-fixed ${menuOpen ? "open" : ""}`}
                >
                  <Index.Box className="header-nav-main">
                    <Index.List className="nav-ul">
                      {headerMenu.map((e) => {
                        return (
                          <Index.ListItem
                            // className="nav-li "
                            className={`nav-li abc ${e.id == currentTab ? "active" : ""}`}>
                            <Index.Box
                              component={"button"}
                              className="nav-link"
                              // className={`nav-link ${e.id===currentTab?"active":""}`}
                              onClick={() => handleScrollToDiv(e.id)}
                            >
                              {e.name}
                            </Index.Box>
                          </Index.ListItem>
                        );
                      })}

                      {/* <Index.ListItem className="nav-li">
                        <Index.Box
                          component={'button'}
                          className="nav-link"
                          onClick={() => handleScrollToDiv('section-2')}
                        >
                          Tokenomics
                        </Index.Box>
                      </Index.ListItem>
                      <Index.ListItem className="nav-li">
                        <Index.Box
                          component={'button'}
                          className="nav-link"
                          onClick={() => handleScrollToDiv('section-3')}
                        >
                          Roadmap
                        </Index.Box>
                      </Index.ListItem>
                      <Index.ListItem className="nav-li">
                        <Index.Box
                          component={'button'}
                          className="nav-link"
                          onClick={() => handleScrollToDiv('section-4')}
                        >
                          Social
                        </Index.Box>
                      </Index.ListItem> */}
                    </Index.List>
                  </Index.Box>
                  <Index.Box className="sidebar-social-main">
                      <Index.List className="sidebar-social-ul">
                        <Index.ListItem className="sidebar-social-li">
                          <Index.Link className="sidebar-social-link" href="" target="_blank">
                            <img src={PagesIndex.Svg.telegram}
                              className="sidebar-social-icons" alt="Social" />
                          </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="sidebar-social-li">
                          <Index.Link className="sidebar-social-link" href="" target="_blank">
                            <img src={PagesIndex.Svg.discord}
                              className="sidebar-social-icons" alt="Social" />
                          </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                className="grid-column"
              >
                <Index.Box className="header-col">
                  <Index.Box className="header-btn-flex fill-btn-main border-btn-main">
                    <Index.Button className="header-btn border-btn mobile-hidden-btn">
                      Wif paper
                    </Index.Button>
                    <Index.Button className="header-btn fill-btn mobile-hidden-btn">
                      Buy DWS
                    </Index.Button>
                    <Index.Button className="btn btn-menu-header" onClick={handleMenuClick}>
                      <img src={PagesIndex.Svg.burgur} alt="burgur" className="burgur-icon"></img>
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </header>
    </>
  );
}
