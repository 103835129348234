import React from "react";

function onlamp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="109"
      viewBox="0 0 17 109"
      fill="none"
    >
      <path
        d="M14.2333 108.174H3.96571L3.96401 107.79C3.88683 90.9325 4.38463 75.1564 4.86615 59.8996C5.07551 53.2655 5.29227 46.4052 5.45954 39.5242L5.46863 39.1489H11.9409L11.9468 39.528C12.2205 57.2609 14.1973 107.269 14.2174 107.772L14.2333 108.174ZM4.69 107.403H13.4745C13.2869 102.636 11.5066 57.1149 11.2253 39.9193H6.1777C6.01148 46.6773 5.79895 53.4103 5.5934 59.9253C5.11569 75.0593 4.6219 90.7037 4.69 107.403Z"
        fill="white"
      />
      <path
        d="M10.1984 31.9697H7.37402V39.5339H10.1984V31.9697Z"
        fill="white"
      />
      <path
        d="M7.87187 4.23392C5.6565 5.52402 5.82271 5.87577 5.10265 8.27999C3.49653 12.5605 -1.43245 13.7332 2.22283 14.3783C2.88749 14.437 4.88124 29.0376 5.21346 28.6859C2.61046 30.8554 6.48715 31.9109 8.81334 32.0869V0.12944C7.48424 0.012117 6.21034 2.53341 7.87187 4.23392Z"
        fill="white"
      />
      <path
        d="M9.69928 4.23392C11.9147 5.52402 11.7484 5.87577 12.4685 8.27999C14.0746 12.5605 19.0038 13.7332 15.3483 14.3783C14.6837 14.437 12.6899 29.0376 12.3577 28.6859C14.9607 30.8554 11.084 31.9109 8.75781 32.0869V0.12944C10.0317 0.012117 11.3608 2.53341 9.69928 4.23392Z"
        fill="white"
      />
      <path
        d="M5.93408 27.513L3.38648 15.0817C3.33108 14.9058 3.44189 14.7886 3.60811 14.7886L6.32192 14.73C6.54354 14.73 6.70954 14.8473 6.70954 15.0817L7.15258 27.337C7.15258 27.6888 6.93095 27.9821 6.59873 27.9821C6.2663 28.0994 5.98927 27.8647 5.93408 27.513Z"
        fill="#240736" className="lamp-on-off"
      />
      <path
        d="M10.973 28.0412C10.6408 28.0412 10.3638 27.7481 10.4192 27.3961L10.8622 15.1408C10.8622 14.9062 10.973 14.7891 11.2499 14.7891L13.9637 14.8477C14.1299 14.8477 14.1853 14.9651 14.1853 15.1408L11.6377 27.5721C11.5269 27.8652 11.2501 28.0998 10.973 28.0412Z"
        fill="#240736" className="lamp-on-off"
      />
      <path
        d="M8.75808 27.9821C8.14883 27.9821 7.87201 27.4544 7.87201 26.8093L7.65039 14.73H9.92117L9.69955 26.7506C9.69955 27.4543 9.42273 27.9821 8.75808 27.9821Z"
        fill="#240736" className="lamp-on-off"
      />
    </svg>
  );
}

export default onlamp;
