import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Index from '../../../../component/Index';
import PagesIndex from '../../../PagesIndex';

const Layout = () => {
  const [currentTab,setCurrentTab]=useState("no tab")
  return (
    <>
      <PagesIndex.Header currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <Outlet context={[currentTab,setCurrentTab]}/>
      <PagesIndex.Footer />
    </>
  )
}

export default Layout;