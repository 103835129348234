import logo from './images/svg/logo.svg';
import DogImg from './images/svg/dog.svg'
import ScrollDown from './images/svg/scroll-down.svg'
import telegram from './images/svg/telegram.svg'
import discord from './images/svg/discord.svg'
import aboutimg from './images/svg/about-bg.svg'
import copyIcon from './images/svg/copy-icon.svg'
import tokenomicsImg from './images/svg/tokenomics.svg'
import tokenBg from './images/svg/token-bg.svg'
import followBg from './images/svg/follow-bg.svg'
import followleftImg from './images/svg/follow-left-img.svg'
import followRightImg from './images/svg/follow-right-img.svg'
import aboutrightImg from './images/svg/about-after-img.svg'
import aboutdog from './images/svg/about-dog.svg'
import coin from './images/svg/coin'
import acloudone from './images/svg/acloud-one.svg'
import acloudtwo from './images/svg/acloud-two.svg'
import acloudthree from './images/svg/acloud-three.svg'
import acloudfour from './images/svg/acloud-four.svg'
import onlamp from './images/svg/onlamp';
import bone from './images/svg/bone';
import arrowup from "./images/svg/arrow-up.svg"
// import waveroadmap from './images/svg/waveroadmap';
import waveroadmap from './images/svg/waveroadmap.svg';
import foxlight from './images/svg/foxlight';
import foxlightleft from './images/svg/foxlightleft';
import roadmapcoin from './images/svg/coin-roadmap.svg';
import burgur from './images/svg/burgur.svg';

const Svg = {
    logo,
    DogImg,
    ScrollDown,
    telegram,
    discord,
    aboutimg,
    copyIcon,
    tokenomicsImg,
    tokenBg,
    followBg,
    followleftImg,
    followRightImg,
    aboutrightImg,
    aboutdog,
    coin,
    acloudone,
    acloudtwo,
    acloudthree,
    acloudfour,
    onlamp,
    bone,
    arrowup,
    waveroadmap,
    foxlight,
    foxlightleft,
    roadmapcoin,
    burgur,
}

export default Svg;