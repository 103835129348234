import React from 'react'
import PagesIndex from '../../PagesIndex'
import Index from '../../Index'
import './defaulLayout.css'
import { PAUSE } from 'redux-persist'

export default function Footer() {
  return (
    <footer className="footer-main">
      <Index.Box className="container">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }} className="footer-row">
            <Index.Box gridColumn={{ xs: "span 12", sm: "span 9", md: "span 8", lg: "span 7" }} className="grid-column">
              <Index.Box className="footer-contait-main">
                <Index.Box className="footer-containt-flex">
                  <Index.Box className="footer-logo-main">
                    <img src={PagesIndex.Svg.logo} alt="Logo"
                      className="footer-logo" />
                  </Index.Box>
                  <Index.Box className="footer-para-main">
                    <Index.Typography className="footer-para">Lorem ipsum dolor sit amet consectetur. A
                      fringilla quis integer ut. Hendrerit fringilla ornare dolor ornare
                      amet ac elementum in nunc. Non elit. Lorem ipsum dolor sit amet
                      consectetur. </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box gridColumn={{ xs: "span 12", sm: "span 3", md: "span 4", lg: "span 5" }} className="grid-column">
              <Index.Box className="footer-contait-main">
                <Index.Box className="footer-social-media-main">
                  <Index.List className="footer-social-ul">
                    <Index.ListItem className="footer-social-li">
                      <Index.Link className="footer-social-link" href="" target="_blank">
                        <img src={PagesIndex.Svg.telegram}
                          className="footer-social-icons" alt="Social" />
                      </Index.Link>
                    </Index.ListItem>
                    <Index.ListItem className="footer-social-li">
                      <Index.Link className="footer-social-link" href="" target="_blank">
                        <img src={PagesIndex.Svg.discord}
                          className="footer-social-icons" alt="Social" />
                      </Index.Link>
                    </Index.ListItem>
                  </Index.List>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
              <Index.Box className="footer-copy-text-main">
                <Index.Typography className="footer-copy-text">©2024 dwf • All Rights Reserved</Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </footer >
  )
}
