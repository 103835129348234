import React, { useEffect, useState } from "react";
import "./herosection.css";
import "./herosection.responsive.css";
import PagesIndex from "../../../../PagesIndex";
import Index from "../../../../Index";


export default function HeroSection({currentTab}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  


  // const [mouseSide, setMouseSide] = useState({
  //   'C':'dog-hover-center',
  //   'R':'dog-hover-right',
  //   'L':'dog-hover-left',
  //   'current':'C'
  // })

  // const handleMouseLeave = () => {
  //   setMouseSide((e)=>({...e,['current']:'C'}));
  // };

  // const handleMouseMove = (e) => {
  //   if ((e.pageX - e.currentTarget.offsetLeft) < e.currentTarget.offsetWidth / 2) {
  //     console.log("maulik-left")
  //     setMouseSide((e)=>({...e,['current']:'L'}));
  //   } else {
  //     console.log("maulik-right")
  //     setMouseSide((e)=>({...e,['current']:'R'}));
  //   }
  // };


  //


  const [scrollPosition, setScrollPosition] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      // Get current scroll position
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
    };

    // Add scroll event listener to window
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run effect only once


  function handleScrollToTop() {
    window.scrollTo({
      top: 900,
      behavior: "smooth",
    });
  }

  return (
    <Index.Box className="hero-section" id="photoContainer">
    {/* <Index.Box className="hero-section" id="photoContainer" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}> */}
      <Index.Box className="container">
        <Index.Box className="row">
          <Index.Box className="hero-containt-main">
            <Index.Box className="hero-wif-main">
              <Index.Box className="hero-wif-text hero-wif-text-left hero-wif-1 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-left hero-wif-2 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-left hero-wif-3 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-left hero-wif-4 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-left hero-wif-5 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-left hero-wif-6 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-right hero-wif-7 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-right hero-wif-8 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-right hero-wif-9 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-right hero-wif-10 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-right hero-wif-11 zoom-in-zoom-out">
                Wif
              </Index.Box>
              <Index.Box className="hero-wif-text hero-wif-text-right hero-wif-12 zoom-in-zoom-out">
                Wif
              </Index.Box>
            </Index.Box>
            <Index.Typography className="hero-subtitle-text">
              Welcome to
            </Index.Typography>
            <Index.Typography
              className={`hero-titles ${scrollPosition>=50?"hero-animation-title":""}`}
            >
              <span className="main-white">Dog</span>{" "}
              <span className="main-green">Wif</span>{" "}
              <span className="main-pink">shades</span>
            </Index.Typography>
    
            <Index.Box className="hero-main-img-box">
            {/* <Index.Box className={`hero-main-img-box ${mouseSide[mouseSide.current]}`} > */}
              <img
                src={PagesIndex.Video.dogvideo}
                className="hero-dog-img"
                alt="Dog"
              />

              {/* <video muted autoPlay='true' loop id="1111" className="hero-dog-img"  >
                <source src={PagesIndex.Video.dogvideo} type="video/mp4" alt="Dog" />
              </video> */}
            </Index.Box>
            <Index.Box className="hero-scroll-down-main bounce">
              {/* <img src={PagesIndex.Svg.ScrollDown} alt="scroll" className="scroll-down-img-box" /> */}
              <Index.Box className="scroll-down-img-box" onClick={handleScrollToTop}>
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  className="scroll-down-animation"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.628 5.47726C25.5738 5.47727 26.4312 5.86181 27.0532 6.48105L28.0581 7.48868L29.0622 6.48247C29.6853 5.8594 30.5413 5.47836 31.4902 5.47732C34.1397 5.47734 36.2894 7.62705 36.2905 10.2776C36.2905 11.6048 35.754 12.8062 34.8833 13.6769L32.7571 15.8032C31.8204 16.7399 31.352 17.9675 31.3506 19.199L31.3518 36.8034C31.3518 38.0314 31.8188 39.2626 32.7604 40.2L34.8853 42.3249C35.755 43.1946 36.2908 44.3939 36.2929 45.7197C36.2926 48.371 34.1408 50.5206 31.4906 50.522C30.5448 50.522 29.6874 50.1375 29.0643 49.5193L28.0605 48.5106L27.0543 49.5189C26.4337 50.1395 25.5773 50.5209 24.6284 50.5219C21.9778 50.523 19.8281 48.3733 19.8281 45.7217C19.8284 44.3941 20.3645 43.1931 21.2331 42.3244L23.3591 40.1985C24.2961 39.2615 24.7666 38.0317 24.7679 36.8002L24.7653 19.1965C24.7653 17.9685 24.2973 16.7384 23.3578 15.7989L21.2329 13.674C20.3622 12.8054 19.8254 11.6071 19.8253 10.2792C19.826 7.62831 21.9781 5.47829 24.628 5.47726Z"
                    fill="white"
                  />
                  <path
                    d="M41.9505 41.3718C41.8554 41.1465 41.6348 41 41.3899 41L14.6087 41.0003C14.3641 41.0003 14.1433 41.1468 14.0479 41.3721C13.9528 41.5974 14.0018 41.858 14.1724 42.0334L27.563 55.8059C27.6775 55.9236 27.835 55.9901 27.9993 55.9901C28.1637 55.9901 28.3212 55.9236 28.4356 55.8059L41.8262 42.0331C41.9968 41.8574 42.0456 41.5971 41.9505 41.3718Z"
                    fill="white"
                  />
                </svg>
              </Index.Box>

     

              {/* <Index.Typography className="scrolldown-text">Scroll Down</Index.Typography> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
