import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from 'history'

//#region user
import { useSelector } from "react-redux";
import Layout from "../container/user/pages/layout/Layout";
import Home from "../container/user/pages/home/Home";
import About from "../component/user/pages/home/about/About";
import Coin from "../component/common/CoinLogo/Coin";
//#endregion



const history = createBrowserHistory();
export default function Routes() {

  const login = useSelector((state) => {
    return state.AdminReducer.isAdminLogin
  });
  // const login = true;

  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="" element={<Layout />}>
          <Route path="/" element={<Home />} />
        </Route>
      </Routess>
    </BrowserRouter>
  );
}
