import React, { useEffect, useRef, useState } from 'react'
import './roadMap.css'
import './roadMap.responsive.css'
import Index from '../../../../Index'
import PagesIndex from "../../../../PagesIndex";
import OwlCarousel from 'react-owl-carousel3';


export default function RoadMap() {

  const [slider, setslider] = useState({
    center : true,
    autoplayHoverPause:false,
    slider: {
      0: {
        items: 1,
        margin: 10,
      },
      767: {
        items: 1,
        margin: 10,
      },
      768: {
        items: 2,
        margin: 10,
      },
      1024: {
        items: 2,
      },
    
      1200: {
        items: 3,
        stagePadding: 60, 
      },
      1300: {
        items: 3,
        stagePadding: 60, 
      },
      1400: {
        items: 4,
        stagePadding: 60, 
      },
      // 1499: {
      //   items: 3,
      //   stagePadding: 60, 
      // },
    }
  })

  const [autoplay, setAutoplay] = useState(true);
  const carouselRef = useRef(null);

  const handleMouseEnter = () => {
    setAutoplay(false);
  };

  const handleMouseLeave = () => {
    setAutoplay(true);
  };

  // Start or stop autoplay based on the state
  useEffect(() => {
    const owlCarouselInstance = carouselRef.current;
    if (owlCarouselInstance) {
      if (autoplay) {
        owlCarouselInstance.play();
      } else {
        owlCarouselInstance.stop();
      }
    }
  }, [autoplay]);


  return (
    <Index.Box className="roadmap-section">
      <Index.Box className="container roadmap-bg-container">
        <Index.Box className="page-title-main roadmap-section-title">
        <Index.motion.div
            animate={{ scale: 0.9  }}
            transition={{ duration: 0.8 }}
            whileInView={{ scale: 1 }}
          >
          <Index.Typography className="page-title">Roadmap</Index.Typography>
          </Index.motion.div>
          <Index.Typography className="page-title-para">Lorem ipsum dolor sit amet consectetur. A fringilla quis integer ut. Hendrerit fringilla ornare dolor
            ornare amet ac elementum in nunc. Non </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="roadmap-bg-section">
        <Index.Box className="roadmap-wave-img">
          <Index.Box className="wave-details"></Index.Box>
        
          {/* <img src={PagesIndex.Svg.waveroadmap} slt="wave" className="wave-details" /> */}
            {/* <PagesIndex.Svg.waveroadmap/> */}
        </Index.Box>
        <Index.Box className="left-fox-roadmap">
              <PagesIndex.Svg.foxlightleft/>
          </Index.Box>
          <Index.Box className="right-fox-roadmap">
              <PagesIndex.Svg.foxlight/>
          </Index.Box>
          <Index.Box className="coin-roadmap-center">
              <img src={PagesIndex.Png.roadmapcoin} alt="coin" className="coin-road-details"/>
          </Index.Box>
        <Index.Box className="container roadmap-container">
          <Index.Box class="row">
            <Index.Box className="roadmap-slider-main">
              <OwlCarousel
                className="owl-theme"
                loop={false}
                margin={36}
                // width={334}
                autoplay={500}
                autoplayHoverPause={!autoplay}
                responsive={slider.slider}
                responsiveClass={true}
                nav={false}
                item={4}
                // center={true}
                onMouseEnter={handleMouseEnter} // Add mouse enter event handler
        onMouseLeave={handleMouseLeave} // Add mouse leave event handler
                dots={false}
              >
                <Index.Box class="item">
                  <Index.Box className="roadmap-box-main">
                    <Index.Typography className='roadmap-box-title'>2012</Index.Typography>
                    <Index.Typography className='roadmap-box-para'>Lorem ipsum dolor sit
                      amet consectetur. A fringilla quis
                      integer ut.</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box class="item">
                  <Index.Box className="roadmap-box-main">
                    <Index.Typography className='roadmap-box-title'>2012</Index.Typography>
                    <Index.Typography className='roadmap-box-para'>Lorem ipsum dolor sit
                      amet consectetur. A fringilla quis
                      integer ut.</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box class="item">
                  <Index.Box className="roadmap-box-main">
                    <Index.Typography className='roadmap-box-title'>2012</Index.Typography>
                    <Index.Typography className='roadmap-box-para'>Lorem ipsum dolor sit
                      amet consectetur. A fringilla quis
                      integer ut.</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box class="item">
                  <Index.Box className="roadmap-box-main">
                    <Index.Typography className='roadmap-box-title'>2012</Index.Typography>
                    <Index.Typography className='roadmap-box-para'>Lorem ipsum dolor sit
                      amet consectetur. A fringilla quis
                      integer ut.</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box class="item">
                  <Index.Box className="roadmap-box-main">
                    <Index.Typography className='roadmap-box-title'>2012</Index.Typography>
                    <Index.Typography className='roadmap-box-para'>Lorem ipsum dolor sit
                      amet consectetur. A fringilla quis
                      integer ut.</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box class="item">
                  <Index.Box className="roadmap-box-main">
                    <Index.Typography className='roadmap-box-title'>2012</Index.Typography>
                    <Index.Typography className='roadmap-box-para'>Lorem ipsum dolor sit
                      amet consectetur. A fringilla quis
                      integer ut.</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box class="item">
                  <Index.Box className="roadmap-box-main">
                    <Index.Typography className='roadmap-box-title'>2012</Index.Typography>
                    <Index.Typography className='roadmap-box-para'>Lorem ipsum dolor sit
                      amet consectetur. A fringilla quis
                      integer ut.</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box class="item">
                  <Index.Box className="roadmap-box-main">
                    <Index.Typography className='roadmap-box-title'>2012</Index.Typography>
                    <Index.Typography className='roadmap-box-para'>Lorem ipsum dolor sit
                      amet consectetur. A fringilla quis
                      integer ut.</Index.Typography>
                  </Index.Box>
                </Index.Box>
              </OwlCarousel>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}
