import Routes from './routes/Routes'
import './assets/style/global.css';
import './assets/style/global.responsive.css';
import './component/user/defaulLayout/defaulLayout.css'
import './component/user/defaulLayout/defaulLayout.responsive.css'

import { createTheme, ThemeProvider } from "@mui/material/styles";
import PagesIndex from './component/PagesIndex';
import Index from './component/Index';

function App() {
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: "400",
        lineHeight: "normal",
      },
    },
  });


  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Routes />
      <Index.Box className="scroll-top">
      <PagesIndex.ScrollTopButton/>

      </Index.Box>
    </div>
    </ThemeProvider>
  );
}

export default App;

