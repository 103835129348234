import React from 'react'

function foxlightleft() {
  return (
<svg class="animated-svg" width="365" height="545" viewBox="0 0 365 545" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="animated-path" opacity="0.39" d="M0.0351257 537.635L131.757 0.75L364.344 134.905L11.3502 544.338L0.0351257 540.535" fill="url(#paint0_linear_457_7662)"/>
<defs>
<linearGradient id="paint0_linear_457_7662" x1="-4.62542" y1="539.314" x2="241.929" y2="79.2493" gradientUnits="userSpaceOnUse">
<stop stop-color="#03FFEF"/>
<stop offset="1" stop-color="#70CBD0" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

  )
}

export default foxlightleft