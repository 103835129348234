import aboutImg from './images/png/about-img.png';
import roadmapbg from './images/png/roadmap-bg.png';
import coinlogo from './images/png/coinlogo.png';
import aboutdog from './images/png/aboutdog.png';
import tokenomicsImg from './images/png/tokenomics.png'
import roadmapcoin from './images/png/coin-roadmap.png';










const Png = {
      aboutImg,
      coinlogo,
      roadmapbg,
      aboutdog,
      tokenomicsImg,
      roadmapcoin,
}

export default Png;