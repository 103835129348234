
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Header from '../component/user/defaulLayout/Header';
import Footer from '../component/user/defaulLayout/Footer'
import HeroSection from '../component/user/pages/home/herosection/HeroSection';
import About from '../component/user/pages/home/about/About';
import RoadMap from '../component/user/pages/home/roadMap/RoadMap';
import Tokenomics from '../component/user/pages/home/tokenomics/Tokenomics';
import Follow from '../component/user/pages/home/follow/Follow';





export default {
      Svg,
      Png,
      Header,
      Footer,
      HeroSection,
      About,
      RoadMap,
      Tokenomics,
      Follow,
};
