import React, { useEffect, useRef, useState } from "react";
import "./follow.css";
import "./follow.responsive.css";
import Index from "../../../../../container/Index";
import PagesIndex from "../../../../PagesIndex";




export default function Follow() {
 
  // const logoRef = useRef(null);


  // useEffect(() => {
  //   const handleScroll = () => {
      
  //     const logoElement = logoRef.current;
  //     if (logoElement ) {
  //       const windowHeight = window.innerHeight;
  //       const scrollPosition = window.scrollY;
  //       const elementPosition = logoElement.offsetTop;
  //       const distanceToTop = elementPosition - scrollPosition;
  //       const isVisible = distanceToTop >= 0 && distanceToTop <= windowHeight;
        
  //       console.log("isVisible:", scrollPosition, windowHeight,elementPosition,);

  //       if (  scrollPosition >=3000) {
  //         console.log("in" )
         
  //         logoElement.classList.add("in-view");
  //       } else {
  //         console.log("out")
       

  //         logoElement.classList.remove("in-view");
  //       }
  //     }
  //   };
  
  //   window.addEventListener("scroll", handleScroll);
  //   handleScroll();
  
    
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [window.scrollY]);


  const logoRef = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const logoElement = logoRef.current;
      if (logoElement) {
        const distanceFromTop = logoElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const isVisible = distanceFromTop.top < windowHeight ;
        console.log(isVisible ,"isVisible" ,distanceFromTop , windowHeight )
         
        if (isVisible && !isAnimated) {
          setIsAnimated(true);
          console.log(isVisible,isAnimated, "in")
        } else {
          setIsAnimated(false);
          console.log(isVisible,isAnimated, "out")
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Index.Box className="follow-section">
      <Index.Box className="follow-flex">
        <Index.Box className="follow-col follow-col1">
          <Index.Box className="follow-left-img-main">
            <Index.Box className="follow-lamp-details">
              <img
                src={PagesIndex.Svg.followleftImg}
                className="follow-left-img"
                alt="Follow"
              />
              <Index.Box className="lamp-one">
                <PagesIndex.Svg.onlamp />
              </Index.Box>
              <Index.Box className="lamp-two">
                <PagesIndex.Svg.onlamp />
              </Index.Box>
            </Index.Box>
            {/* <Index.Box className="follow-logo-bottm" ref={logoRef}> */}
            <Index.Box className={`follow-logo-bottm desktop-logo-follow ${isAnimated ? 'in-view' : ''}`} ref={logoRef}>
              <img
                src={PagesIndex.Svg.logo}
                className="follow-icon-logo animation-element"
                alt="Follow"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="follow-col follow-col2">
          <Index.Box className="follow-containt-main">
            <Index.motion.div 
              animate={{ y: 200, opacity: 0 }}
              transition={{ duration: 0.2 }}
              whileInView={{ y: 0, opacity: 1 }}
            >
              <Index.Typography className="follow-text">
                Follow us on Social Media
              </Index.Typography>
            </Index.motion.div>
            <Index.Box className="follow-social-main">
              <Index.List className="follow-social-ul">
                <Index.ListItem className="follow-social-li">
                  <Index.Link
                    className="follow-social-link"
                    href=""
                    target="_blank"
                  >
                    <img
                      src={PagesIndex.Svg.telegram}
                      className="follow-social-icons"
                      alt="Social"
                    />
                  </Index.Link>
                </Index.ListItem>
                <Index.ListItem className="follow-social-li">
                  <Index.Link
                    className="follow-social-link"
                    href=""
                    target="_blank"
                  >
                    <img
                      src={PagesIndex.Svg.discord}
                      className="follow-social-icons"
                      alt="Social"
                    />
                  </Index.Link>
                </Index.ListItem>
              </Index.List>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="follow-col follow-col3">
          <Index.Box className="follow-right-img-main">
            <img
              src={PagesIndex.Svg.followRightImg}
              className="follow-right-img"
              alt="Follow"
            />
            <Index.Box className="lamp-three">
              <PagesIndex.Svg.onlamp />
            </Index.Box>
            <Index.Box className="lamp-four">
              <PagesIndex.Svg.onlamp />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
